<template>
  <base-index-layout :title="$tc('lot.name', 2)">
    <template #new-button>
      <base-new-button class="mx-4" :to="{ path: 'new' }" append />
    </template>
    <ItemGroupLotIndexTable />
  </base-index-layout>
</template>

<script>
import ItemGroupLotIndexTable from './components/ItemGroupLotIndexTable'

export default {
  components: {
    ItemGroupLotIndexTable
  }
}
</script>
