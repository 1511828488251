var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemGroupsLot,"loading":_vm.isLoading,"loader-height":"1","items-per-page":15,"sort-by":"attributes.createdAt","footer-props":{
    itemsPerPageOptions: [15, 25, 50 - 1]
  }},scopedSlots:_vm._u([{key:`item.attributes.facilityId`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getFacility(item.relationships.facility.data))+" ")]}},{key:`item.id`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.$refs.dialog.createQrCode(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-qrcode-scan")])],1),_c('BaseTableActions',{attrs:{"module-name":"itemGroupLot","item":item,"to-show":{
        name: 'item-group-lot-show',
        params: { itemGroupLotId: item.id }
      },"to-edit":{
        name: 'item-group-lot-edit',
        params: {
          itemGroupLotId: item.id
        }
      }}}),_c('qr-code-lot-generator',{ref:"dialog",attrs:{"url":_vm.qrUrl,"facility":_vm.getFacility(item.relationships.facility.data)}})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }