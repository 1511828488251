<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title />
      <v-card-text>
        <div
          :id="resource ? resource.id : 'qr-code'"
          class="text-center border-1 heavy"
        >
          <div class="text-h5 font-weight-bold black--text">
            {{ itemDescription }} - ({{ itemsCount }} Items)
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="qrCode" />
          <div class="text-h5 font-weight-bold black--text">
            {{ facility }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          depressed
          text
          :loading="isLoading"
          @click="onClick()"
        >
          <v-icon left>mdi-printer</v-icon>
          {{ $t('actions.print') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Printjs from 'print-js'
import domtoimage from 'dom-to-image'

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    facility: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      qrCode: '',
      isLoading: false,
      resource: null
    }
  },

  computed: {
    itemDescription() {
      return this.resource?.attributes?.description
    },
    itemsCount() {
      return this.resource?.attributes?.itemsCount
    }
  },

  methods: {
    async createQrCode(res) {
      if (!res.id) return
      this.resource = res
      this.qrCode = await this.generateQrCode()
      this.dialog = true
    },
    async generateQrCode() {
      const url = this.url + this.resource.id
      const response = await this.$store.dispatch(
        'terms/fetch',
        `/codes/qr?code=${url}`
      )
      return response.attributes.qrCode
    },
    onClick() {
      this.isLoading = true
      setTimeout(() => {
        this.htmlToImage()
      }, 100)
    },
    htmlToImage() {
      const node = document.getElementById(this.resource.id)
      domtoimage
        .toPng(node)
        .then(dataUrl => {
          Printjs({
            printable: dataUrl,
            type: 'image',
            base64: true
          })
        })
        .catch(e => {
          console.error('Something went wrong!', e)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.border-1 {
  border: 2px solid black;
  border-radius: 10px;
  image-rendering: pixelated;
}
</style>
