<template>
  <v-data-table
    :headers="headers"
    :items="itemGroupsLot"
    :loading="isLoading"
    loader-height="1"
    :items-per-page="15"
    sort-by="attributes.createdAt"
    :footer-props="{
      itemsPerPageOptions: [15, 25, 50 - 1]
    }"
  >
    <template v-slot:[`item.attributes.facilityId`]="{ item }">
      {{ getFacility(item.relationships.facility.data) }}
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <v-btn
        small
        color="primary"
        dark
        icon
        @click="$refs.dialog.createQrCode(item)"
      >
        <v-icon small>mdi-qrcode-scan</v-icon>
      </v-btn>
      <BaseTableActions
        module-name="itemGroupLot"
        :item="item"
        :to-show="{
          name: 'item-group-lot-show',
          params: { itemGroupLotId: item.id }
        }"
        :to-edit="{
          name: 'item-group-lot-edit',
          params: {
            itemGroupLotId: item.id
          }
        }"
      />

      <qr-code-lot-generator
        ref="dialog"
        :url="qrUrl"
        :facility="getFacility(item.relationships.facility.data)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import BaseTableActions from '@/components/buttons/BaseTableActions'
import QrCodeLotGenerator from './QrCodeLotGenerator.vue'
export default {
  name: 'ItemGroupLotIndexTable',
  components: {
    BaseTableActions,
    QrCodeLotGenerator
  },
  data() {
    return {
      userGroups: [],
      isLoading: true,
      facilities: []
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      itemGroupsLot: 'itemGroupLot/itemGroupsLot'
    }),
    headers() {
      return this.createTableHeaders()
    },
    qrUrl: () => `${window.location.origin}/es/inventory/item-groups/lot/`
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      this.isLoading = true
      await this.filterItemGroupsLot()
      this.facilities = await this.fetchFacilities()
      this.isLoading = false
    },
    async filterItemGroupsLot() {
      await this.$store.dispatch('itemGroupLot/filter', {
        organizationId: ORGANIZATION_ID(),
        category: 'lot'
      })
    },
    async fetchFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    getFacility(data) {
      if (!data || !this.facilities.length) return
      const {
        attributes: { name }
      } = this.facilities.find(facility => facility.id === data.id)
      return name
    },
    removeRow(item) {
      const answer = window.confirm(this.$t('alerts.delete'))
      if (answer) {
        this.$store.dispatch('itemGroupLot/delete', item)
      }
    },
    createTableHeaders() {
      return [
        {
          text: this.$tc('lot.name', 1) + '#',
          align: 'start',
          value: 'attributes.description'
        },
        {
          text: this.$tc('item.name', 2),
          value: 'relationships.items.data.length'
        },
        {
          text: this.$t('facility.location'),
          value: 'attributes.facilityId'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped></style>
